const CITIES = [
  {
    slug: "berlin",
    src: "https://wunderflatsng.blob.core.windows.net/artefacts/city-images-webp/Berlin_400.webp",
    coverImageSrcSet: `https://wunderflatsng.blob.core.windows.net/artefacts/city-images-webp/Berlin_400.webp 312w,
    https://wunderflatsng.blob.core.windows.net/artefacts/city-images-webp/Berlin_800.webp 624w`,
    coatOfArmsUrl:
      "https://wunderflatsng.blob.core.windows.net/artefacts/homepage-webp/cities/Berlin%20Coat%20of%20Arms.webp",
  },
  {
    slug: "munich",
    src: "https://wunderflatsng.blob.core.windows.net/artefacts/city-images-webp/Munich_400.webp",
    coverImageSrcSet: `https://wunderflatsng.blob.core.windows.net/artefacts/city-images-webp/Munich_400.webp 312w,
    https://wunderflatsng.blob.core.windows.net/artefacts/city-images-webp/Munich_800.webp 624w`,
    coatOfArmsUrl:
      "https://wunderflatsng.blob.core.windows.net/artefacts/homepage-webp/cities/Munich%20Coat%20of%20Arms.webp",
  },
  {
    slug: "paris",
    src: "https://wunderflatsng.blob.core.windows.net/artefacts/city-images-webp/Paris_400.webp",
    coverImageSrcSet: `https://wunderflatsng.blob.core.windows.net/artefacts/city-images-webp/Paris_400.webp 312w,
    https://wunderflatsng.blob.core.windows.net/artefacts/city-images-webp/Paris_800.webp 624w`,
    coatOfArmsUrl:
      "https://wunderflatsng.blob.core.windows.net/artefacts/homepage-webp/cities/Paris%20Coat%20of%20Arms.webp",
  },
  {
    slug: "hamburg",
    src: "https://wunderflatsng.blob.core.windows.net/artefacts/city-images-webp/Hamburg_400.webp",
    coverImageSrcSet: `https://wunderflatsng.blob.core.windows.net/artefacts/city-images-webp/Hamburg_400.webp 312w,
    https://wunderflatsng.blob.core.windows.net/artefacts/city-images-webp/Hamburg_800.webp 624w`,
    coatOfArmsUrl:
      "https://wunderflatsng.blob.core.windows.net/artefacts/homepage-webp/cities/Hamburg%20Coat%20of%20Arms.webp",
  },
  {
    slug: "frankfurt",
    src: "https://wunderflatsng.blob.core.windows.net/artefacts/city-images-webp/Frankfurt_400.webp",
    coverImageSrcSet: `https://wunderflatsng.blob.core.windows.net/artefacts/city-images-webp/Frankfurt_400.webp 312w,
    https://wunderflatsng.blob.core.windows.net/artefacts/city-images-webp/Frankfurt_800.webp 624w`,
    coatOfArmsUrl:
      "https://wunderflatsng.blob.core.windows.net/artefacts/homepage-webp/cities/Frankfurt%20Coat%20of%20Arms.webp",
  },
  {
    slug: "dusseldorf",
    src: "https://wunderflatsng.blob.core.windows.net/artefacts/city-images-webp/Dusseldorf_400.webp",
    coverImageSrcSet: `https://wunderflatsng.blob.core.windows.net/artefacts/city-images-webp/Dusseldorf_400.webp 312w,
      https://wunderflatsng.blob.core.windows.net/artefacts/city-images-webp/Dusseldorf_800.webp 624w`,
    coatOfArmsUrl:
      "https://wunderflatsng.blob.core.windows.net/artefacts/homepage-webp/cities/Dusseldorf%20Coat%20of%20Arms.webp",
  },
  {
    slug: "leipzig",
    src: "https://wunderflatsng.blob.core.windows.net/artefacts/city-images-webp/Leipzig_400.webp",
    coverImageSrcSet: `https://wunderflatsng.blob.core.windows.net/artefacts/city-images-webp/Leipzig_400.webp 312w,
        https://wunderflatsng.blob.core.windows.net/artefacts/city-images-webp/Leipzig_800.webp 624w`,
    coatOfArmsUrl:
      "https://wunderflatsng.blob.core.windows.net/artefacts/homepage-webp/cities/Leipzig%20Coat%20of%20Arms.webp",
  },
  {
    slug: "nuremberg",
    src: "https://wunderflatsng.blob.core.windows.net/artefacts/city-images-webp/Nuremburg_400.webp",
    coverImageSrcSet: `https://wunderflatsng.blob.core.windows.net/artefacts/city-images-webp/Nuremburg_400.webp 312w,
    https://wunderflatsng.blob.core.windows.net/artefacts/city-images-webp/Nuremburg_800.webp 624w`,
    coatOfArmsUrl:
      "https://wunderflatsng.blob.core.windows.net/artefacts/homepage-webp/cities/Nuremburg%20Coat%20of%20Arms.webp",
  },
  {
    slug: "stuttgart",
    src: "https://wunderflatsng.blob.core.windows.net/artefacts/city-images-webp/Stuttgart_400.webp",
    coverImageSrcSet: `https://wunderflatsng.blob.core.windows.net/artefacts/city-images-webp/Stuttgart_400.webp 312w,
    https://wunderflatsng.blob.core.windows.net/artefacts/city-images-webp/Stuttgart_800.webp 624w`,
    coatOfArmsUrl:
      "https://wunderflatsng.blob.core.windows.net/artefacts/homepage-webp/cities/Stuttgart%20Coat%20of%20Arms.webp",
  },
  {
    slug: "essen",
    src: "https://wunderflatsng.blob.core.windows.net/artefacts/city-images-webp/Essen_400.webp",
    coverImageSrcSet: `https://wunderflatsng.blob.core.windows.net/artefacts/city-images-webp/Essen_400.webp 312w,
    https://wunderflatsng.blob.core.windows.net/artefacts/city-images-webp/Essen_800.webp 624w`,
    coatOfArmsUrl:
      "https://wunderflatsng.blob.core.windows.net/artefacts/homepage-webp/cities/Essen%20Coat%20of%20Arms.webp",
  },
  {
    slug: "dresden",
    src: "https://wunderflatsng.blob.core.windows.net/artefacts/city-images-webp/Dresden_400.webp",
    coverImageSrcSet: `https://wunderflatsng.blob.core.windows.net/artefacts/city-images-webp/Dresden_400.webp 312w,
    https://wunderflatsng.blob.core.windows.net/artefacts/city-images-webp/Dresden_800.webp 624w`,
    coatOfArmsUrl:
      "https://wunderflatsng.blob.core.windows.net/artefacts/homepage-webp/cities/Dresden%20Coat%20of%20Arms.webp",
  },
  {
    slug: "bremen",
    src: "https://wunderflatsng.blob.core.windows.net/artefacts/city-images-webp/Bremen_400.webp",
    coverImageSrcSet: `https://wunderflatsng.blob.core.windows.net/artefacts/city-images-webp/Bremen_400.webp 312w,
    https://wunderflatsng.blob.core.windows.net/artefacts/city-images-webp/Bremen_800.webp 624w`,
    coatOfArmsUrl:
      "https://wunderflatsng.blob.core.windows.net/artefacts/homepage-webp/cities/Bremen%20Coat%20of%20Arms.webp",
  },
];

export default CITIES;
