import dayjs from "dayjs";

const formatDatePreviews = (
  fromDate,
  toDate,
  defaultFromString,
  defaultToString,
) => {
  const fromDateFormat =
    fromDate && toDate && dayjs(fromDate).year() !== dayjs(toDate).year()
      ? "MMM D, YYYY"
      : "MMM D";

  const fromDatePreview = fromDate
    ? dayjs(fromDate).format(fromDateFormat)
    : defaultFromString;

  const toDatePreview = toDate
    ? dayjs(toDate).format("MMM D, YYYY")
    : defaultToString;

  return { fromDatePreview, toDatePreview };
};

const isoFormat = (date) => {
  return date ? dayjs(date).format("YYYY-MM-DD") : null;
};

export { formatDatePreviews, isoFormat };
