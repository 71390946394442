import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
import classNames from "classnames";
import dayjs from "dayjs";
import { useI18n } from "../../../spages/spa/context/I18nContext";
import { Button } from "../../DesignSystem/Button/Button";
import Popover, { placements } from "../../Popover/Popover";
import { FROM_DATE, TO_DATE } from "../constants";
import { formatDatePreviews, isoFormat } from "../dateFormatting";
import "./DateRangePickerTrigger.scss";

const DateRangePicker = loadable(() => import("../DateRangePicker"), {
  ssr: false,
});

const propTypes = {
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  minBookingDuration: PropTypes.number,
  minDate: PropTypes.objectOf((propValue) => dayjs.isDayjs(propValue)),
  maxYear: PropTypes.number,
  isDayBlocked: PropTypes.func,
  dateRangeSeparator: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  popoverClassName: PropTypes.string,
  triggerClassName: PropTypes.string,
  previewButtonClassName: PropTypes.string,
  previewButtonActiveClassName: PropTypes.string,
  previewButtonInactiveClassName: PropTypes.string,
  previewButtonSelectedClassName: PropTypes.string,
  dateSeparatorClassName: PropTypes.string,
  dateSeparatorInactiveClassName: PropTypes.string,
};

const DateRangePickerTrigger = forwardRef(
  (
    {
      fromDate,
      toDate,
      minBookingDuration = 1,
      minDate,
      maxYear,
      isDayBlocked,
      dateRangeSeparator,
      onChange,
      onClose = () => {},
      popoverClassName,
      triggerClassName,
      previewButtonClassName,
      previewButtonActiveClassName,
      previewButtonInactiveClassName,
      previewButtonSelectedClassName,
      dateSeparatorClassName,
      dateSeparatorInactiveClassName,
    },
    ref,
  ) => {
    const [hoveredFromDate, setHoveredFromDate] = useState(null);
    const [hoveredToDate, setHoveredToDate] = useState(null);
    const [focusedInput, setFocusedInput] = useState(
      fromDate ? TO_DATE : FROM_DATE,
    );
    const [isVisible, setIsVisible] = useState(false);
    const fromPreviewButtonRef = useRef();
    const { t } = useI18n();

    useImperativeHandle(ref, () => ({
      focus: () => {
        setIsVisible(true);
        fromPreviewButtonRef?.current.focus();
      },
    }));

    const fromDateValue = fromDate ?? hoveredFromDate ?? null;
    const toDateValue = toDate ?? hoveredToDate ?? null;
    const { fromDatePreview, toDatePreview } = formatDatePreviews(
      fromDateValue,
      toDateValue,
      t("components.DateRangePicker.moveIn"),
      t("components.DateRangePicker.moveOut"),
    );

    let defaultMonth = dayjs();
    if (focusedInput === TO_DATE && toDate) {
      defaultMonth = dayjs(toDate);
    } else if (fromDate) {
      defaultMonth = dayjs(fromDate);
    }

    const onChangeDatesHandler = ({ fromDate, toDate }) => {
      onChange({
        fromDate: isoFormat(fromDate),
        toDate: isoFormat(toDate),
      });
    };

    useEffect(() => {
      setFocusedInput(fromDate ? TO_DATE : FROM_DATE);
    }, [fromDate]);

    const onHoverDatesHandler = ({ hoveredFromDate, hoveredToDate }) => {
      setHoveredFromDate(hoveredFromDate);
      setHoveredToDate(hoveredToDate);
    };

    const onClearDatesHandler = () => {
      onChange({ fromDate: null, toDate: null });
      setFocusedInput(FROM_DATE);
    };

    const onFromDatePreviewClickHandler = () => {
      if (!isVisible) {
        return;
      }

      if (focusedInput !== FROM_DATE) {
        setFocusedInput(FROM_DATE);
        setHoveredToDate(null);
        onChange({ fromDate: isoFormat(fromDate), toDate: null });
      }
    };

    const onToDatePreviewClickHandler = () => {
      if (!isVisible) {
        return;
      }
      if (fromDate && focusedInput !== TO_DATE) {
        setFocusedInput(TO_DATE);
      }
    };

    const onPopoverCloseHandler = () => {
      setIsVisible(false);
      onClose();
    };

    const fromDateClassNames = classNames(previewButtonClassName, {
      [previewButtonInactiveClassName]: !fromDate,
      [previewButtonActiveClassName]:
        isVisible && focusedInput === FROM_DATE && !fromDate,
      [previewButtonSelectedClassName]:
        isVisible && focusedInput === FROM_DATE && fromDate,
    });

    const toDateClassNames = classNames(previewButtonClassName, {
      [previewButtonInactiveClassName]: !toDate,
      [previewButtonActiveClassName]:
        isVisible && focusedInput === TO_DATE && !toDate,
      [previewButtonSelectedClassName]:
        isVisible && focusedInput === TO_DATE && toDate,
    });

    const separatorClassName = classNames(dateSeparatorClassName, {
      [dateSeparatorInactiveClassName]: !fromDate,
    });

    const isClearDatesButtonShown = fromDate && toDate;

    return (
      <Popover
        show={isVisible}
        placement={placements.bottomStart}
        usePortal={false}
        hasArrow={false}
        className={classNames(
          "DateRangePickerTrigger-popover",
          popoverClassName,
        )}
        onClose={onPopoverCloseHandler}
        around={
          <div
            onClick={() => setIsVisible(true)}
            className={triggerClassName}
            data-testid="DateRangePickerTrigger"
          >
            <button
              ref={fromPreviewButtonRef}
              onClick={onFromDatePreviewClickHandler}
              className={fromDateClassNames}
              data-testid="DateRangePickerTrigger-fromDate"
              type="button"
            >
              {fromDatePreview}
            </button>
            <span className={separatorClassName}>{dateRangeSeparator}</span>
            <button
              onClick={onToDatePreviewClickHandler}
              className={toDateClassNames}
              data-testid="DateRangePickerTrigger-toDate"
              type="button"
            >
              {toDatePreview}
            </button>
          </div>
        }
      >
        <DateRangePicker
          fromDate={fromDate ? dayjs(fromDate) : null}
          toDate={toDate ? dayjs(toDate) : null}
          hoveredFromDate={hoveredFromDate}
          hoveredToDate={hoveredToDate}
          focusedInput={focusedInput}
          onChangeDates={onChangeDatesHandler}
          onHoverDates={onHoverDatesHandler}
          minBookingDuration={minBookingDuration}
          minDate={minDate}
          maxYear={maxYear}
          isDayBlocked={isDayBlocked}
          defaultMonth={defaultMonth}
        />
        <footer className="DateRangePickerTrigger-footer">
          {isClearDatesButtonShown ? (
            <Button
              dataTestId="DataRangePickerTrigger-clearDates"
              colorVariant="secondary"
              widthVariant="growWithText"
              size="medium"
              onClick={onClearDatesHandler}
            >
              {t("components.DateRangePicker.clearDates")}
            </Button>
          ) : (
            t("components.DateRangePicker.minBookingDuration", {
              minBookingDuration,
            })
          )}
        </footer>
      </Popover>
    );
  },
);

DateRangePickerTrigger.propTypes = propTypes;
export default DateRangePickerTrigger;
