import React, { useRef } from "react";
import PropTypes from "prop-types";
import { misc } from "@wunderflats/constants";
import { useHistory } from "react-router-dom";
import {
  EXPERIMENT_NAME_RANKING,
  useExperiments,
} from "../../../../../analytics/experiments";
import GeocodingSearch from "../../../../../components/GeocodingSearch/GeocodingSearch";
import { useAuth } from "../../../../../contexts/AuthContext";
import { useFeatureFlags } from "../../../../../contexts/FeatureFlagContext";
import * as defaultListingFilterUtil from "../../../../../utils/default-listing-filter";
import {
  getGeocodingItemEnglishLabel,
  getGeocodingItemLabel,
} from "../../../../../utils/geocodingHelpers";
import { safeDecodeComponent } from "../../../../../utils/url";
import { useTracker } from "../../../context/TrackerContext";
import DateFilter from "./DateFilter/DateFilter";
import SearchBarDateRangePicker from "./SearchBarDateRangePicker/SearchBarDateRangePicker";
import SearchIcon from "./SearchIcon";

const {
  FEATURE_FLAGS: { ENVIRONMENT_DATE_PICKER_2022_HOMEPAGE },
} = misc;

const SearchBar = ({
  defaultFilters,
  lang,
  t,
  url,
  analyticsEvent = () => {},
}) => {
  const { isFeatureFlagEnabled } = useFeatureFlags();
  const showNewDatePicker = isFeatureFlagEnabled(
    ENVIRONMENT_DATE_PICKER_2022_HOMEPAGE,
  );

  const { user } = useAuth();
  const { tracker } = useTracker();

  const dateFilterRef = useRef();
  const dateRangePickerRef = useRef();

  const history = useHistory();

  const { experiments } = useExperiments();

  const defaultFormValues = {
    from: defaultFilters.from || null,
    to: defaultFilters.to || null,
    region: defaultFilters.region
      ? safeDecodeComponent(defaultFilters.region)
      : null,
    search: defaultFilters.search || null,
    city: defaultFilters.city ? safeDecodeComponent(defaultFilters.city) : null,
  };
  const [formValues, setFormValues] = React.useState(defaultFormValues);

  const onSubmit = (event) => {
    event.preventDefault();

    // Clear empty values
    const values = Object.keys(formValues).reduce((acc, key) => {
      if (!formValues[key]) {
        return acc;
      }

      acc[key] = formValues[key];

      return acc;
    }, {});

    const region = values.region || "berlin";

    const redirectURL = url(
      "listinglist",
      { region, lang },
      {
        query: {
          from: values.from,
          to: values.to,
          search: values.search,
          // Do not remove scoreVariant - permanent ranking A/B test
          scoreVariant: experiments?.[EXPERIMENT_NAME_RANKING] || "A",
        },
      },
    );

    defaultListingFilterUtil.removeCookie();
    defaultListingFilterUtil.set("region", encodeURIComponent(region));
    if (values.search) {
      defaultListingFilterUtil.set("search", values.search);
    }
    defaultListingFilterUtil.set("from", values.from);

    if (values.city) {
      defaultListingFilterUtil.set("city", encodeURIComponent(values.city));
    }

    if (values.from && values.to) {
      defaultListingFilterUtil.set("to", values.to);
    }
    analyticsEvent("HomePage", "home_click_search");

    tracker.events.trackSearchOnHomepage({
      userId: user?._id || null,
      location: region,
      fromDate: values.from,
      toDate: values.to || null,
    });

    history.push(redirectURL);
  };

  const submitButton = useRef();

  const onDateFilterChange = ({ from, to }) => {
    analyticsEvent(
      "HomePage",
      "home_set_search",
      `dates_from:${from}_to:${to}`,
    );
    setFormValues({ ...formValues, from, to });

    submitButton.current.focus();
  };

  const onDateRangeChange = ({ fromDate, toDate }) => {
    analyticsEvent(
      "HomePage",
      "home_set_search",
      `dates_from:${fromDate}_to:${toDate}`,
    );
    setFormValues({ ...formValues, from: fromDate, to: toDate });

    if (toDate) {
      submitButton.current.focus();
    }
  };

  const onSearch = (value) => {
    const searchText = getGeocodingItemLabel(value);
    setFormValues({
      ...formValues,
      region: searchText,
      search: value.id,
      city: value.region,
    });

    const searchTextEn = getGeocodingItemEnglishLabel(value);
    analyticsEvent("HomePage", "home_new_search", searchTextEn);

    if (!formValues.from) {
      if (showNewDatePicker) {
        dateRangePickerRef?.current.focus();
      } else {
        dateFilterRef?.current.focus();
      }
    } else {
      submitButton.current.focus();
    }
  };

  return (
    <article className="SearchBar">
      <form className="SearchBarForm" onSubmit={onSubmit}>
        <div className="SearchBar-GeocodingSearch">
          <span className="SearchBarForm-Input-title">{t("city")}</span>
          <GeocodingSearch
            lang={lang}
            variant="light"
            showInputIcon={false}
            value={formValues.region || ""}
            onSelectSuggestion={onSearch}
            placeholder={t("spages.homepage.searchBar.region.placeholder")}
            hideSuggestionArrow
            suggestionBoxClassName={
              showNewDatePicker ? "SearchBarForm-InputAutocomplete" : null
            }
          />
        </div>
        <div className="SearchBarForm-separator" />

        {showNewDatePicker ? (
          <SearchBarDateRangePicker
            ref={dateRangePickerRef}
            fromDate={formValues.from}
            toDate={formValues.to}
            onClose={() => submitButton.current.focus()}
            onChange={onDateRangeChange}
          />
        ) : (
          <DateFilter
            from={formValues.from}
            onChange={onDateFilterChange}
            ref={dateFilterRef}
            t={t}
            to={formValues.to}
            onClose={() => submitButton.current.focus()}
          />
        )}

        <button
          type="submit"
          className="SearchBarForm-SubmitButton"
          ref={submitButton}
          aria-label={t("spages.homepage.searchBar.submitButton")}
        >
          <SearchIcon className="SearchBarForm-SubmitButton-icon" />
          <span className="SearchBarForm-SubmitButton-text">
            {t("spages.homepage.searchBar.submitButton")}
          </span>
        </button>
      </form>
    </article>
  );
};

SearchBar.propTypes = {
  defaultFilters: PropTypes.object,
  lang: PropTypes.string.isRequired,
  regions: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  url: PropTypes.func.isRequired,
  analyticsEvent: PropTypes.func,
};

export default SearchBar;
